@charset "utf-8";

/* Images Loading via CSS due Plugin Limitation */
:root {
  --avatar-size: 2.5rem;
}

/* CSS Document */
@font-face {
  font-family: "Inter";
  src:
    url("assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("assets/fonts/Inter-Regular.woff") format("woff"),
    url("assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("assets/fonts/Inter-Bold.woff") format("woff"),
    url("assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("assets/fonts/Inter-SemiBold.woff") format("woff"),
    url("assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("assets/fonts/Inter-Medium.woff2") format("woff2"),
    url("assets/fonts/Inter-Medium.woff") format("woff"),
    url("assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* general start*/
body {
  background: #fbf9f9;
  color: #222;
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  font-size: 14px;
  padding: 0.4rem 1.5rem;
}

.modal-backdrop {
  z-index: 10000;
}

.unsigned-page-header {
  margin-top: 70px;
}

.fs-30 {
  font-size: 30px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 20px;
}

.modal-open .modal {
  overflow: hidden;
  z-index: 10001;
}

.mx-15 {
  margin: auto 15px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Inter" !important;
  font-size: inherit;
  line-height: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul li {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
}

h1 {
  font-size: 40px;
  line-height: 1.2;
  color: #2e404e;
  font-weight: bold;
}

h2 {
  font-size: 35px;
  line-height: 1.2;
  color: #2e404e;
  font-weight: bold;
}

h3 {
  font-size: 25px;
  line-height: 1.2;
  color: #222;
}

h4 {
  font-size: 20px;
  line-height: 1.2;
  color: #222;
}

h5 {
  font-size: 18px;
  line-height: 1.3;
  color: #222;
}

p {
  padding: 0 0 20px 0;
  margin: 0;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #217ce8;
  outline: none;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #000;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none !important;
  outline: none !important;
}

a > img {
  line-height: 0;
}

select,
button,
input {
  box-shadow: none;
  outline: none;
}

select:hover,
button:hover,
input:hover,
select:focus,
button:focus,
input:focus {
  box-shadow: none;
  outline: none;
}

select,
.formsectionbox select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(assets/images/seclect-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 16px;
}

.formsectionbox .form-control {
  height: 100%;
  padding: 13px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.formsectionbox .form-control::placeholder {
  opacity: 0.5;
}

.text-ccc {
  color: #ccc;
}

.btn_submit,
input[type="submit"],
input.btn_submit[type="submit"] {
  background: #019bc1;
  color: #fff;
  border: 1px solid #019bc1;
  border-radius: 0;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  padding: 15px 30px;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.btn_submit:hover,
input[type="submit"]:hover,
input.btn_submit[type="submit"]:hover,
.btn_submit:focus,
input[type="submit"]:focus,
input.btn_submit[type="submit"]:focus {
  background: #019bc1;
  color: #fff;
  outline: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

/* general end*/
header.header {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.mnutog {
  display: none;
}

.mw-170 {
  min-width: 170px;
}

.mw-110 {
  min-width: 110px;
}

.quickguidesec {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.466);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.quickguidebox {
  background-color: #fff;
  width: 500px;
  font-size: 18px;
  margin: auto;
}

.disablesec {
  display: none !important;
}

.quickguidesmbox {
  width: 230px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.123);
  position: absolute;
  z-index: 1;
}

.quickguidesec2 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.206);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.quickboxposition1 {
  top: 60px;
  left: 440px;
}

.quickboxposition2 {
  top: 220px;
  left: 850px;
}

.quickboxposition3 {
  top: 220px;
  left: 1040px;
}

.quickguidesmbox.quickboxposition1::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 22px 14px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: -1;
}

.doc-header {
  color: red;
}

.preserve-line-breaks {
  white-space: pre;
  width: 100%;
  text-wrap: balance !important;
}

.react-toast-notifications__toast {
  width: 100% !important;
}

.quickguidesmbox.quickboxposition2::before,
.quickguidesmbox.quickboxposition3::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14.5px 22px 14.5px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  z-index: -1;
}

.qckgdnum {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  background-color: #007bff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.aicompanysec .nav-link {
  color: #000;
  font-size: 16px;
  padding-left: 0;
  padding-right: 60px;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.aicompanysec .nav-link:hover {
  color: #007bff;
}

.aicompanysec .nav-link.active {
  color: #007bff;
}

#model-search-icon {
  margin-left: -35px;
  padding: 12px 10px;
  background-color: #025bda;
  color: white;
  border-radius: 5px;
}

big {
  font-size: 130%;
  font-weight: 300;
  line-height: 1.8;
}

.btn1 {
  padding: 15px 25px;
  border: none;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  background: #217ce8;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.btn1.arrowhover,
.btn1.arrowhover span {
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.btn1.arrowhover span {
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
}

.btn1.arrowhover:hover span {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
}

.btn1:hover {
  color: #fff;
  background: #000f24;
}

.btn1:disabled {
  background-color: lightgray;
  cursor: no-drop;
}

footer.footer {
  padding: 20px 0;
  background: #333;
  color: #fff;
}

.contentWrpper {
  width: 100%;
  height: calc(100vh - 100px);
}

.contentWrpper h1 {
  font-size: 30px;
  font-weight: 500;
}

.loginBox {
  background-image: url(assets/images/blueBg.png);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginwrap {
  margin-top: 70px;
}

.contentWrpper > .row {
  height: 100%;
}

.loginbox {
  box-shadow: 0px 0px 10px #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.loginbox {
  width: 450px;
}

.loginbox-mob {
  width: 90%;
  box-shadow: 0px 0px 10px #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.graddientbtn {
  background: rgb(0, 74, 153);
  background: linear-gradient(
    10deg,
    rgba(0, 74, 153, 1) 0%,
    rgba(0, 104, 215, 1) 27%,
    rgba(46, 147, 255, 1) 100%
  );
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 6px 15px;
  text-transform: uppercase;
  transition: all 400ms;
  border: 1px solid #0d3f75;
}

.graddientbtn:hover {
  color: #ececec;
}

.askmebtn span {
  background-color: #f1f1f1;
  border: 1px solid #217ce8;
  padding: 8px 25px;
  color: #000;
  border-radius: 0px 50px 50px 0px;
  position: relative;
  margin-left: -15px;
  z-index: -1;
  transition: all 400ms;
}

.askmebtn .qstnbgclr {
  fill: #217ce8;
  transition: all 400ms;
}

.askmebtn .qstnclr {
  fill: #fff;
}

.askmebtn span b {
  text-transform: uppercase;
}

.askmebtn:hover span {
  background-color: #217ce8;
  color: #fff;
}

.askmebtn:hover .qstnbgclr {
  fill: #000;
}

.templatebox {
  width: 60%;
}

.loginfailedbox {
  background-color: #fac5c552;
  font-size: 13px;
}

.loginfailedbox.message-alert-success {
  background-color: #35f23152;
  font-size: 13px;
}

.text-uppercase {
  text-transform: uppercase;
}

.loginfailedbox a {
  color: #222;
  text-decoration: underline;
}

.form-control {
  height: 100%;
  padding: 13px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 700ms;
  -webkit-transition: all 700ms;
  -moz-transition: all 700ms;
  -ms-transition: all 700ms;
  -o-transition: all 700ms;
  font-size: 14px;
}

.formsectionbox .form-control {
  height: 100%;
  padding: 13px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.formsectionbox .form-control::placeholder {
  opacity: 0.5;
}

.form-control:focus,
.form-control-focus {
  color: #222;
  background-color: #007bff11;
  border-color: #ccc;
  outline: 0;
  box-shadow: none !important;
}

.form-group {
  margin-bottom: 1.3rem;
}

.nxtbuttungrp {
  text-align: center;
  display: inherit !important;
  margin: auto;
}

.btn-primary {
  border-radius: 0;
}

.nxtbuttungrp .btn.btn-primary {
  background-color: #217ce8 !important;
  border: 0 !important;
}

.nxtbuttungrp .btn.btn-primary img {
  width: 16px;
}

.loginbg {
  background-color: #fff;
  border-radius: 10px;
  width: 450px;
  padding: 60px 100px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  z-index: 1;
}

.loginbg::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.521);
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  z-index: -1;
}

.loginbg::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.521);
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  margin: auto;
  z-index: -1;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnupld {
  border: 1px solid #ced4da;
  color: #495057;
  background-color: white;
  padding: 11px 10px 11px 20px;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
}

.btnupld-blu {
  border: 1px solid #ced4da;
  color: #fff;
  background-color: #217ce8;
  padding: 11px 10px 11px 20px;
  cursor: pointer;
}

.btnupld-blu:hover {
  border: 1px solid #ced4da;
  background-color: #000;
}

.btnupld-blu svg {
  margin: 0 5px;
}

.btnupld img {
  width: 22px;
  margin-left: 20px;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.smalltext {
  font-size: 12px;
  color: #666;
}

.loginbg .form-control::placeholder {
  opacity: 1;
  color: #217ce8;
}

.loginbg .form-control {
  box-shadow: 0px 0px 10px #ddd;
  padding: 9px 15px;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 17px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 5px 35px 5px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: 40px;
  color: #217ce8;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
  border: 1px solid #ced4da;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background: #217ce8;
  color: #fff;
  z-index: 25;
  font-size: 12px;
  line-height: 40px;
  padding: 0 10px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 7px 7px 0;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
}

.file-upload-wrapper:hover:before {
  background: #217ce8;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.sitetitle {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #000;
}

.sitetitle span {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.sitetitle span img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.formbox1 {
  box-shadow: 0px 0px 10px #ddd;
}

.titlesec h3 {
  color: #025bda;
  margin-bottom: 5px;
}

.titlesec p {
  color: #217ce8;
}

.testmonialbox {
  padding: 30px;
  background-image: url(assets/images/testtibg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.leftboxitems img {
  width: 60px;
}

@media (min-width: 1200px) {
  .container-max {
    max-width: 1250px;
  }
}

.container-max {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.kinkedinboc img {
  width: 240px;
}

.frmbtnsets .btn1 {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #217ce8;
  font-size: 13px;
}

.frmbtnsets .btn1:hover {
  background-color: #333;
}

.tt-select {
  position: relative;
  height: auto;
  padding: 0px;
  margin-bottom: 30px;
  text-align: left;
}

.tt-select select {
  display: none;
}

.pophovetoolhovr {
  transition: all 400ms;
}

.pophovetoolhovr:hover {
  transform: translateY(-5px);
}

/* .tt-select select,
.customselect select {
  display: none;
} */
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: rgb(73, 73, 73);
  width: 100%;
  height: 40px;
}

.select-selected {
  color: #222;
  padding: 9px 35px 9px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.lang-select {
  width: 50px;
}

.lang-select {
  background-size: 15px !important;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

.select-selected::before {
  position: absolute;
  content: "";
  top: 11px;
  right: 12px;
  /*appearance: none;
    */
  background-image: url(assets/images/seclect-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 16px;
  z-index: 999;
  width: 20px;
  height: 20px;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.select-selected.select-arrow-active:before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.select-items {
  background-color: #fff;
  box-shadow: 0px 0px 10px #ddd;
}

.select-items div {
  color: #222;
  padding: 8px 16px;
  border-bottom: 1px solid rgb(233, 233, 233);
  cursor: pointer;
}

.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.customselect {
  height: auto;
  padding: 0px;
  text-align: left;
  position: relative;
}

.customselect .select-items span {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.customselect .select-items span::before {
  content: attr(data-text);
  position: relative;
  left: 0;
  top: 0;
  padding: 0 5px;
  /* border: 1px solid #ddd; */
  color: #666;
  width: 100%;
  display: block;
}

.customselect .select-items {
  height: 250px;
  overflow: auto;
}

.loginBox h2 {
  font-size: 25px;
  font-weight: 600;
}

.clcultesec {
  width: 100%;
  margin-left: auto;
  background-color: #0751a1;
  height: 100%;
  overflow: auto;
}

.clcultesec .form-control {
  background-color: rgba(255, 255, 255, 0.171);
  color: #fff;
  font-size: 14px;
}

.clcultesec .form-control::placeholder {
  color: #fff;
}

.radious-5 {
  border-radius: 5px;
}

.clcultesec .btn1 {
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  color: #0751a1;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.clcultesec .btn1:hover {
  background-color: #222;
  color: #fff;
}

.clcultesec .btn1:disabled {
  background-color: #888888;
  color: #fff;
}

.bluestrip {
  background-color: #c7dffa;
  font-size: 20px;
  color: #222;
  font-weight: 600;
}

.bluestrip div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bluestrip span {
  font-size: 40px;
  font-weight: bold;
  margin-left: 10px;
}

.Sidepnel {
  width: 200px;
  background-color: #f8f8f8;
  padding: 20px;
  height: 100vh;
  position: fixed;
  left: -200px;
  /* top: 76px; */
  top: 0;
  bottom: 0;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.iconclic {
  height: 80px;
  background-color: #c7dffa;
  padding: 0 10px;
  display: flex;
  width: 39px;
  border-radius: 0px 7px 7px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  right: -39px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.iconclic img {
  width: 10px;
}

.Sidepnel.active {
  left: 0;
}

.iconclic.iconactive img {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}

.row-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.flowsec {
  margin: 0 5%;
}

.flowbox h3 {
  font-weight: bold;
  font-size: 28px;
}

.flowbox h4 {
  font-weight: bold;
  font-size: 19px;
  text-transform: uppercase;
  min-height: 50px;
}

.flowsec .row .col-md-4.col-lg-3:nth-child(1) .flowbox h4 {
}

.flowsec .row .col-md-4.col-lg-3:nth-child(2) .flowbox h4 {
}

.flowsec .row .col-md-4.col-lg-3:nth-child(3) .flowbox h4 {
}

.flowsec .row .col-md-4.col-lg-3:nth-child(4) .flowbox h4 {
}

.blurefect {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

/* ============Dashbord CSS=============== */
.dahboardheader {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}

.Avtarimg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
}

.leftbar {
  background-color: #000f24;
  width: 230px;
  position: fixed;
  left: 0;
  top: 71px;
  height: calc(100% - 71px);
  z-index: 100;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.leftbar.active {
  width: 75px;
}

.activeoverlaybar {
  background-color: #000f24;
  filter: opacity(0.6);
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 99;
}

.leftbar .nav .navlist li a span {
  transition: all 400ms;
  transform: translateX(00px);
  -webkit-transform: translateX(00px);
  -moz-transform: translateX(00px);
  -ms-transform: translateX(00px);
  -o-transform: translateX(00px);
  display: inline-block;
}

.leftbar.active .nav ul li span {
  opacity: 0;
  transform: translateX(-15px);
  -webkit-transform: translateX(-15px);
  -moz-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  -o-transform: translateX(-15px);
  position: relative;
}

.dashboardwrap.active {
  margin-left: 230px;
  height: calc(100vh - 71px);
}

.height-inherit {
  height: inherit;
}

.dashboardwrap {
  height: calc(100vh - 71px);
  top: 71px;
  position: relative;
  margin-left: 80px;
  transition: all 400ms;
}

.object-container {
  position: relative;
}

.object-container .image-container {
  width: auto;
  height: auto;
}

.image-select-indicator {
  width: 20px;
  height: 20px;
}

.object-container .selection-box {
  position: relative;
}

.ai-image-cropped {
  opacity: 0.8;
}

.ai-image-cropped .ai-image-success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.object-container .coordinates {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

textarea.form-control {
  min-height: 150px;
}

.iconclic2 {
  height: 50px;
  background-color: #000f24;
  padding: 0 10px;
  display: flex;
  width: 24px;
  border-radius: 0px 7px 7px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  right: -24px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.iconclic2 img {
  width: 10px;
  transform: rotate(-180deg);
}

.iconclic2.iconactive img {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
}

.leftbar .nav li img {
  width: 17px;
  margin-right: 20px;
}

.leftbar .nav li a {
  color: #fff;
  margin-bottom: 30px;
  display: inline-block;
}

.leftbar .nav .navlist li a {
  margin-bottom: 30px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  position: relative;
}

.leftbar .nav .navlist li:nth-child(2) > ul > li,
.leftbar .nav .navlist li:nth-child(3) > ul > li,
.leftbar .nav .navlist li:nth-child(4) > ul > li,
.leftbar .nav .navlist li:nth-child(5) > ul > li,
.leftbar .nav .navlist li:nth-child(6) > ul > li {
  margin-left: 22px;
}

.leftbar .nav .navlist li a:hover {
  color: #007bff;
}

.leftbar .nav .navlist > li:nth-child(1) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(2) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(3) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(3) a.collapsed::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.leftbar .nav .navlist li:nth-child(3) ul > li:nth-child(1) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(3) ul > li:nth-child(1) a::after {
  content: "";
  position: absolute;
  left: -17px;
  background-image: url(assets/images/bottomline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  top: -26px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(3) ul > li:nth-child(2) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(3) ul > li:nth-child(3) a::before {
  content: "";
  position: absolute;
  left: -16px;
  background-image: url(assets/images/topline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(4) a.collapsed::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(4) ul > li:nth-child(1) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(4) ul > li:nth-child(1) a::after {
  content: "";
  position: absolute;
  left: -17px;
  background-image: url(assets/images/bottomline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  top: -26px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(4) ul > li:nth-child(2) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(4) ul > li:nth-child(3) a::before {
  content: "";
  position: absolute;
  left: -16px;
  background-image: url(assets/images/topline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(4) a.collapsed::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist > li:nth-child(5) a.collapsed::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(5) ul > li:nth-child(1) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(5) ul > li:nth-child(1) a::after {
  content: "";
  position: absolute;
  left: -17px;
  background-image: url(assets/images/bottomline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  top: -26px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(5) ul > li:nth-child(2) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(5) ul > li:nth-child(3) a::before {
  content: "";
  position: absolute;
  left: -16px;
  background-image: url(assets/images/topline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(6) ul > li:nth-child(1) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(6) ul > li:nth-child(1) a::after {
  content: "";
  position: absolute;
  left: -17px;
  background-image: url(assets/images/bottomline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 25px;
  top: -26px;
  width: 100%;
  height: 24px;
}

.leftbar .nav .navlist li:nth-child(6) ul > li:nth-child(2) a::before {
  content: "";
  position: absolute;
  left: 7px;
  background-image: url(assets/images/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 2.5px;
  bottom: -30px;
  width: 100%;
  height: 24px;
}

.daynumber {
  min-width: 42px;
  min-height: 42px;
  max-width: 42px;
  max-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: -25px;
}

.left-n-5 {
  left: -25px !important;
}

.top-80 {
  top: 80px;
  height: auto !important;
}

/* .dhbbox-outer {
  clear: both;
}

.dhbbox {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border: dotted rgb(175, 175, 175) 1px;
}

.dhbbox1 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border: dashed #b4b0b0 1px;
  box-shadow: 0px 0px 10px #cccccc;
  background: #fff;
} */

.dhbbox-top {
  border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -ms-border-radius: 15px 15px 0 0;
  -o-border-radius: 15px 15px 0 0;
  background: #217ce8;
  position: relative;
  z-index: -1;
  bottom: -10px;
  font-size: 13px;
}

.dhbbox-top img {
  padding-right: 10px;
  width: 30px;
}

.leftbar .nav::-webkit-scrollbar {
  width: 0px;
}

.clcultesec select {
  font-family: "inter";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.f-13 {
  font-size: 13px;
}

.clcultesec option {
  color: #000;
}

.btnpill {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.Orangebox {
  background-color: #fce6d8;
  font-size: 13px;
}

.Orangebox h5 {
  color: #eb702c;
}

.Orangebox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.violetebox {
  background-color: #d8e0fc;
  font-size: 13px;
}

.violetebox h5 {
  color: #7f97ec;
}

.violetebox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.flowmidbox {
  background-color: #c7dffa;
  margin: auto;
  width: 600px;
  text-align: center;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

.flowmidbox h4 {
  font-weight: 600;
  line-height: 1.6;
}

.flowmidbox big {
  font-weight: 600;
  font-size: 30px;
}

.btn1radiuos {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  text-transform: uppercase;
}

.btn1radiuos:disabled {
  background-color: lightgray;
  cursor: no-drop;
}

.flowmidbox span.h1 {
  font-weight: bold;
}

.branchdtlsbox {
  position: relative;
  margin-top: 60px;
}

.branchdtlsbox::before {
  content: "";
  background-image: url(assets/images/brunchline3.svg);
  position: absolute;
  width: 75%;
  top: -300px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.file-upload-wrapper2 {
  position: relative;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.file-upload-wrapper2:after {
  content: attr(data-text);
  font-size: 14px;
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.171);
  color: #333;
  padding: 10px 22px 10px 10px;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 20;
  border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
}

.file-upload-wrapper2:before {
  content: "";
  background-image: url(assets/images/upload.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  color: #fff;
  z-index: 25;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.file-upload-wrapper2 input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.file-upload-wrapper4 {
  position: relative;
  width: 100%;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.file-upload-wrapper4:after {
  content: attr(data-text);
  font-size: 14px;
  position: relative;
  top: 0;
  left: 0;
  background-color: #007bff;
  color: #fff;
  padding: 10px 22px 10px 10px;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 20;
  border-radius: 0;
}

.ask-me-loader-container {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.file-upload-wrapper4:before {
  content: "";
  background-image: url(assets/images/upload.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  color: #fff;
  z-index: 25;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.file-upload-wrapper4 input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.formsectionbox .form-control {
  height: 100%;
  padding: 13px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.formsectionbox .form-control::placeholder {
  opacity: 0.5;
}

.borderbottom {
  border-bottom: #217ce8 solid 3px;
}

.file-upload-wrapper3:after {
  content: "";
  font-size: 14px;
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.171);
  color: #fff;
  padding: 10px 22px 10px 10px;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 20;
  border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
}

.file-upload-wrapper3:before {
  content: "";
  background-image: url(assets/images/upload-file.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  color: #fff;
  z-index: 25;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.file-upload-wrapper3 input {
  opacity: 0;
  position: absolute;
  top: -10px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #025bda;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-file-upload:hover,
.custom-file-upload:focus {
  background-color: #333;
}

/* Define dimensions for the container */
.table-container {
  width: 100%;
  height: 450px;
  /* Adjust height as needed */
  overflow: auto;
  text-align: center;
}

/* Style the table */
.table-container table {
  width: 100%;
  border-collapse: collapse;
}

/* Style table header */
.table-container thead th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
}

/* Style table rows */
.table-container tbody td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

/* Highlight alternating rows */
.table-container tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hide horizontal scrollbar */
.table-container::-webkit-scrollbar {
  display: none;
}

.revenuetbl thead {
  background-color: #b4d3f3;
  color: #217ce8;
  font-size: 12px;
}

.zoomable-image-container {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.zoomable-image-container.zoomed {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomable-image {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
}

.zoomable-image-container.zoomed .zoomable-image {
  transform: scale(1);
}

.ai-knowledge-list {
  max-height: 70vh;
  overflow: auto;
  /* background: aliceblue; */
  padding: 15px;
  border-radius: 10px;
}

.img-tag {
  background-color: lightgrey;
  border-radius: 25px;
}

.ai-knowledge-list-item {
  /* border-bottom: 1px solid lightgray */
}

.tabpaelcnt li.nav-item {
  width: 33.33%;
  text-align: center;
}

.tabpaelcnt .nav-tabs .nav-link {
  margin-bottom: 0px;
  border: 1px solid transparent;
  background: #1d1d1d;
  color: #fff;
  padding: 15px;
  border-radius: 0;
  font-size: 16px;
}

.tabpaelcnt .nav-tabs .nav-item.show .nav-link,
.tabpaelcnt .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.tabpaelcnt .nav-tabs .border-right {
  border-right: 1px solid #007bff !important;
}

.tabpaelcnt .checktasks label span.tskchk {
  border: 0px solid #fff;
  display: block;
  padding: 0px;
  position: relative;
  cursor: pointer;
  transition: all 300ms;
  padding: 12px 20px;
  min-width: 210px;
}

.tabpaelcnt .checktasks label input[type="checkbox"]:checked ~ span.tskchk {
  border: 0;
}

.tabpaelcnt .checktasks label span.tskchk::before {
  border-bottom: 1px solid #6f6d6d;
  border-right: 1px solid #6f6d6d;
}

.ai-knowledge-list:last-child {
  border-bottom: none;
}

.ai-kw-ai-question {
  text-transform: capitalize;
}

.ai-kw-ai-answer {
}

.ai-multi-img-main {
  width: 60%;
  margin: 0 auto;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-27 {
  width: 27%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.bg-gray-progress {
  background-color: lightgray !important;
}

.bg-white-smoke {
  background-color: whitesmoke;
}

.evaluate-tooltip {
  z-index: 12000;
}

.evaluate-tooltip .tooltip-inner {
  position: absolute;
  left: 15px;
  top: -15px;
  background-color: #000000ee;
  color: white;
  min-width: 200px;
}

/** LOADER MASK ANIMTION **/
.line {
  height: 15px;
  border-radius: 5px;
}

.pre {
  background: linear-gradient(to right, #eeeeee 8%, #e1e1e1 18%, #eeeeee 33%);
  background-size: 200% 50px;
  -webkit-animation: preloadAnimation 2s infinite;
  animation: preloadAnimation 2s infinite;
}

@-webkit-keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }

  to {
    background-position: 300px 0;
  }
}

@keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }

  to {
    background-position: 300px 0;
  }
}

.gap-min {
  gap: 0.25rem;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.agentbox {
  background-color: #fff;
  transition: all 400ms;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  margin: 0 10px;
  width: 200px;
}

.agentbox:hover {
  background-color: #e0e9f1;
}

.agenticon {
  background-color: #007bff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 5px;
}

.choose-agent-dropdown {
}

.choose-agent-dropdown .select {
}

.choose-agent-dropdown .select-styled {
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px;
  bottom: auto;
  top: -5px;
}

.btn-create-agent {
  padding: 10px 25px;
}

.choose-agent-dropdown .select-styled::after {
  top: 15px;
  right: 10px;
}

.agentedit {
  position: absolute;
  top: 0;
  right: 5px;
}

.agentWrapbox {
  white-space: nowrap;
  overflow: auto;
  padding-bottom: 5px;
}

.agentWrapbox::-webkit-scrollbar {
  height: 4px;
}

.agentWrapbox::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-top: 15px;
}

.agentWrapbox::-webkit-scrollbar-thumb {
  background: #007bff;
}

.agent-box-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.sampleinfo {
  background-color: #007bff11;
  color: #474646;
  width: 380px;
}

.btn2 {
  background-color: #217ce8;
  border-radius: 00px;
  -webkit-border-radius: 00px;
  -moz-border-radius: 00px;
  -ms-border-radius: 00px;
  -o-border-radius: 00px;
  padding: 10px 30px;
  color: #fff;
  border: 0;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  font-size: 14px;
}

.btn2:hover {
  background-color: #000f24;
  color: #fff;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn1next {
  padding: 12px 25px;
  border: none;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  background: #025bda;
  display: inline-block;
  font-size: 16px;
}

.flowprocessbox {
  font-size: 14px;
  position: relative;
  width: 270px;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 20px;
}

.flowprocessbox div:nth-child(2) {
  bottom: 30px;
  position: absolute;
  left: -30px;
}

.flowprocessbox div:nth-child(3) {
  bottom: 30px;
  position: absolute;
  left: 84px;
}

.flowprocessbox div:nth-child(4) {
  top: 30px;
  position: absolute;
  right: 30px;
}

.flowprocessbox div:nth-child(5) {
  bottom: -40px;
  position: absolute;
  right: 33px;
}

.h100vh {
  height: 100vh;
}

.btnclps img {
  width: 20px;
}

.btnclps {
  border-bottom: 1px solid #fff;
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.recrd {
  position: relative;
}

.recrd .form-control {
  padding-right: 30px;
}

.recrd img {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 30px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 25px;
}

.modal-lg,
.modal-xl {
  max-width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.clcultesec .close {
  opacity: 1;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.ui.selection.dropdown {
  cursor: pointer;
  line-height: auto;
  white-space: normal;
  outline: 0;
  min-width: 100%;
  min-height: auto;
  padding: 9px;
  background-color: rgba(255, 255, 255, 0.171);
  color: #fff;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 7px;
  -webkit-transition:
    box-shadow 0.1s ease,
    width 0.1s ease;
  transition:
    box-shadow 0.1s ease,
    width 0.1s ease;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.ui.label {
  color: #fff;
  font-weight: normal;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: rgba(255, 255, 255, 0.87);
}

.ui.multiple.dropdown > .text {
  margin: 0;
  line-height: 1.21428571em;
}

.ui.selection.dropdown:hover {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.ui.fluid.dropdown > .dropdown.icon {
  float: right;
  display: none;
}

.ui.selection.dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(./assets/images/seclect-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 16px;
}

.ui.label > a {
  opacity: 1;
  color: #222;
}

.ui.multiple.dropdown > .label {
  padding: 4px 4px;
  box-shadow: none;
  font-size: 10px;
}

.popuptabs .nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  color: #222;
}

.nav-tabs {
  border-bottom: 1px solid #007bff;
}

.popuptabs .nav-tabs .nav-item.show .nav-link {
  border: 0 !important;
}

.popuptabs .nav-tabs .nav-link.active {
  color: #222;
  background-color: #fff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid #007bff;
}

.popuptabs .tab-content {
  max-height: 70vh;
  overflow: auto;
}

.evaluate-ai .tab-content > .active {
  min-height: 50vh;
}
.tab-pane {
  white-space: pre-wrap;
}

/****************************************   Custome styles Vishwas ********************************************* */
.clcultesec select {
  text-transform: capitalize;
}

.page-content {
  height: fit-content;
}

.btn-clear {
  border: none;
  background: none;
}

.full-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  align-items: center;
  z-index: 1;
}

.ai-model-creator {
  text-align: center;
  overflow-x: auto;
  scrollbar-color: #217ce8 lightgray;
  scrollbar-width: inherit;
}
.ai-model-creator::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar track */
  height: 15px;
  border-radius: 6px;
}

.ai-model-creator::-webkit-scrollbar-thumb {
  background-color: #000; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

.ai-model-creator::-webkit-scrollbar-track {
  background-color: lightgray; /* Color of the track */
}

.unsigned-placeholder {
  height: 500px;
  margin: 0 auto;
}
.unsigned-chart-container {
  opacity: 0.3;
  cursor: no-drop;
}

.ai-model-creator::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar track */
  height: 15px;
  border-radius: 6px;
}

.ai-model-creator::-webkit-scrollbar-thumb {
  background-color: #000;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

.ai-model-creator::-webkit-scrollbar-track {
  background-color: lightgray;
  /* Color of the track */
}

.unsigned-placeholder {
  height: 500px;
  margin: 0 auto;
}

.unsigned-chart-container {
  opacity: 0.3;
  cursor: no-drop;
}

.ai-model-platform {
  display: inline-flex;
  padding: 15px;
  overflow-x: auto;
  position: relative;
  height: 470px;
}

.checkbox-wrapper-15 .cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.checkbox-wrapper-15 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-15 .cbx span:first-child {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #b9b8c3;
  transition: all 0.2s ease;
}

.checkbox-wrapper-15 .cbx span:first-child svg {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 6px;
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-15 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #506eec;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition-delay: 0.2s;
}

.checkbox-wrapper-15 .cbx span:last-child {
  margin-left: 8px;
}

.checkbox-wrapper-15 .cbx span:last-child:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #b9b8c3;
  transform-origin: 0 0;
  transform: scaleX(0);
}

.chat-search-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.chtbot-results {
  max-height: 65vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.checkbox-wrapper-15 .cbx:hover span:first-child {
  border-color: #3c53c7;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child {
  border-color: #3c53c7;
  background: #3c53c7;
  animation: check-15 0.6s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child {
  color: #b9b8c3;
  transition: all 0.3s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child:after {
  transform: scaleX(1);
  transition: all 0.3s ease;
}

@keyframes check-15 {
  50% {
    transform: scale(1.2);
  }
}

.ai-model {
  position: relative;
}

.ai-model button.hover-btn {
  display: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  border: 0;
  z-index: 100;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 11px;
}

.ai-model:hover button.hover-btn {
  display: inline-block;
}

.ai-pipe-title {
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  text-align: center;
}

.ai-chip-input input {
}

.chat-text-left {
  text-align: left;
}

.chat-text-right {
  text-align: right;
}

.suggestedtag {
  box-shadow: 0px 0px 10px #ddd;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  position: absolute;
  width: 100%;
  z-index: 9999;
  left: 0;
  top: 100%;
  background-color: #fff;
}

.suggestedtaglist {
  display: flex;
  flex-wrap: wrap;
}

--react-chip-input__chip-bg-color,
--react-chip-input__chip-hover-border-color {
  background-color: transparent;
}

.suggestedtaglist li {
  color: #000;
  border: 1px solid #ddd;
  padding: 6px 10px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.suggestedtaglist li:hover {
  background-color: #007bff11;
}

.ai-model-step {
  position: relative;
  font-size: 12px;
}

.ai-btn-add {
  background-color: #217ce8;
  position: absolute;
  right: -40px;
  bottom: 200px;
}

.ai-btn-add:hover {
  background-color: #1759aa;
}

.ai-btn-delete {
  background-color: #ff0000;
  position: absolute;
  left: calc(50% - 10px);
  bottom: 0px;
}

.border-blue {
  border-color: #007bff !important;
}

.edtmdlbtn {
  cursor: pointer;
}

.edtmdlbtn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-left-width: 0px;
  border-right-width: 55px;
  border-top: 50px solid;
  z-index: 0;
}

.edtmdlbtn-icon {
  top: 0px;
  left: 20px;
}

.modelupdatebox {
  font-size: 12px;
  width: 90%;
  margin-left: 5%;
  text-align: left;
  gap: 8px;
}

.bluebg::before {
  border-top-color: #217ce8;
}

.yellowbg::before {
  border-top-color: #ffb802;
}

.greenbg::before {
  border-top-color: #058447;
}

.ai-btn-delete:hover {
  background-color: #aa0202;
}

.overlap-ai-model {
  margin-left: -31px;
}

.department-from {
  margin-left: 15px;
  padding: 15px;
  background-color: #0751a1;
  color: white;
}

.ai-department-form {
  flex: 1;
}

.ai-model-creator {
  flex: 6;
}

.separator {
  border-bottom: 1px dashed white;
}

.separator-top {
  border-top: 1px dashed white;
}

.ai-model-name {
  margin: 0 auto;
  width: 25%;
}

.ul-list {
  list-style: disc;
}

#myTab .nav-item .nav-link {
  font-weight: bold;
}

.chatbox {
  margin: auto;
}

.chatbotanswersec {
  background-color: #000000;
  height: 100vh;
  width: 100%;
  max-height: 100%;
  position: fixed;
  left: 0;
  z-index: 9999999;
  top: 71px;
  bottom: 0;
  color: #fff;
  /* overflow: auto; */
  transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transform: translateY(-200%);
  opacity: 0;
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -o-transform: translateY(-200%);
  z-index: -1;
}

.chatbotanswersec.showchatboxoptions {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  z-index: 1;
}

.chatbotanswersec3 {
  background-color: #000000;
  height: 100vh;
  width: 100%;
  padding-left: 290px;
  padding-right: 290px;
  position: fixed;
  left: 0;
  z-index: 9999999;
  top: 0px;
  color: #fff;
  overflow: auto;
  transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
}

.chatbotanswersec3.active-left {
  padding-left: 0;
}

.chatbotanswersec3.active-right {
  padding-right: 0;
}

.leftbar3 {
  width: 290px;
  background-color: #1d1d1d;
  position: fixed;
  height: calc(100vh - 71px);
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 400ms;
}

.leftbar3.active {
  left: -290px;
}

.rightbar3 {
  width: 290px;
  background-color: #1d1d1d;
  position: fixed;
  height: calc(100vh - 71px);
  top: 0;
  right: 0;
  z-index: 99;
  transition: all 400ms;
}

.rightbar3.active {
  right: -290px;
}

.iconclic4 {
  height: 50px;
  background-color: #1d1d1d;
  padding: 0 10px;
  display: flex;
  width: 24px;
  border-radius: 0px 7px 7px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  right: -24px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.iconclic4 img {
  width: 10px;
  transform: rotate(-180deg);
}

.iconclic4.iconactive3 img {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
}

.rightbar3 .iconclic4 {
  border-radius: 7px 0px 0px 7px;
  right: 290px;
}

.rightbar3 img {
}

.rightbar3 .iconclic4 img {
}

.powered-by {
  color: #abababbf;
}

.serchrghtselect select {
  border: 0;
  background-color: #1d1d1d !important;
  height: auto;
  color: #fff;
  transition: all 0.2s ease-in-out;
  padding: 20px;
  border-color: transparent;
}

.serchrghtselect select {
  background-image: url(assets/images/seclect-arrow.png);
  background-color: transparent !important;
  cursor: pointer;
  border-color: transparent !important;
}

.serchrghtselect .form-select {
  border: none;
}

.fileUpload {
  position: relative;
}

.fileUpload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.fileUpload button {
  cursor: pointer;
}

.fileUpload span {
  cursor: pointer;
  display: block;
}

.textgrey {
  color: #abababbf;
}

.border-bottom-grey {
  border-bottom: 1px solid #abababbf !important;
}

.border-gray {
  border: 1px solid #abababbf !important;
}

.bg-transpatrent {
  background: transparent;
}

.cursor-pointer {
  cursor: pointer !important;
}

.serchrghtselect .form-select option {
  background-color: #1d1d1d;
  color: white;
  /* Optional: to ensure text is visible */
  padding: 5px !important;
  cursor: pointer;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-dropdown .dropdown-toggle::after {
  background-image: url(assets/images/seclect-arrow.png);
  content: "";
  background-size: 14px auto;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  right: -10px;
  top: 58%;
  transform: translateY(-50%);
  display: block;
  margin-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.custom-dropdown .dropdown-toggle {
  background-color: transparent;
  border: 0;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  color: #fff;
  position: relative;
  min-width: 150px;
}

.custom-dropdown .dropdown-selected {
  text-transform: capitalize;
}

.light-dropdown.custom-dropdown .dropdown-item {
  color: #000000;
  text-transform: capitalize;
}

.light-dropdown.custom-dropdown .dropdown-item:hover {
  color: white;
}

.custom-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #1d1d1d;
  z-index: 1000;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.serchrghtselect .dropdown-menu {
  padding: 0 0;
  border-radius: 0;
}

.custom-dropdown .dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: #fff;
  /* Text color */
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #007bff;
  /* Hover effect */
  color: #fff;
}

.dropdown-divider {
  border-top: 1px solid #ccc;
  margin: 0;
}

.profile .dropdown-item {
  color: #222;
}

.profile .dropdown-item:hover {
  background-color: #007bff;
  /* Hover effect */
  color: #fff;
}

.custom-dropdown.active .dropdown-menu {
  display: block;
}

.dotmaindv {
  width: 90px;
}

.dotsvg {
  width: 7px;
  height: 7px;
  border-radius: 100%;
}

.dot1 {
  background: #abababbf;
}

.dot2 {
  width: 8px;
  height: 8px;
  background: #bbb;
}

.dot3 {
  width: 9px;
  height: 9px;
  background: #fff;
}

.gap2 {
  gap: 7px;
}

.mx-h-65 {
  max-height: 65vh;
  overflow: auto;
}

.mx-h-54 {
  max-height: 54vh;
  overflow: auto;
}

.chatbotsidebar {
  background-color: #1d1d1d;
  overflow: auto;
}

.chatbotanscontent {
  overflow: auto;
  max-height: 100vh;
  overflow: auto;
}

.chatbotsidebarlist li {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #e0e0e0;
  margin-right: 10px;
}

.border-grey {
  border-color: #777 !important;
}

.crossdismiss {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  z-index: 10000000;
}

.file-iframe {
  width: 100%;
  height: 70vh;
}

.questaccordionbox .btn-link:hover {
  text-decoration: none;
}

.questaccordionbox .btn-link {
  color: #fff;
  border-bottom: 1px solid #666;
  display: block;
  padding: 10px 10px 10px 0;
  margin-top: 0px;
  position: relative;
  font-size: 17px;
  cursor: pointer;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.questaccordionbox .btn-link::before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 0;
  transform: rotate(180deg);
  background-image: url(assets/images/accr-arrow.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: all 400ms;
}

.questaccordionbox .btn-link.collapsed::before {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: all 400ms;
}

.ai-pipe-icon {
  width: 20px;
  height: 20px;
}

.ai-model-task-info {
  position: absolute;
  left: 25px;
  right: 35px;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
}

.ai-value-added {
  padding: 10px 0;
  color: #333;
}

.ai-model-task-info.short {
  left: -34px;
}

.ai-model-title {
  margin: 10px auto;
  font-size: 14px;
  text-transform: capitalize;
}

.ai-model-value-added-title {
}

/* .ai-task-label {
  position: relative;
  display: block;
} */

.ai-task-label-info {
  margin-bottom: 0;
  display: block;
}

.ai-model-container {
  position: relative;
}

.ai-task-name {
  text-align: left;
  font-size: 12px;
  top: 40px;
  word-break: break-word;
  position: absolute;
  text-align: center;
}

.ai-task-name.ai-work-flow {
  top: 320px;
}

.sugestedbox ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sugestedbox ul li {
  background-color: #007bff28;
  color: #007bff;
  padding: 4px 7px 5px 7px;
  font-size: 12px;

  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 5px;
  line-height: 1.1;
}

.sugestedbox ul li svg {
  cursor: pointer;
  border-radius: 50%;
}

.sugestedbox ul li svg:hover {
  color: #000;
  border: solid 1px #217ce8;
}

.header-logo {
  width: 55px;
  height: 55px;
}

.fancy {
  --b: 6px;
  /* control the border thickness */
  --w: 80px;
  /* control the width of the line*/
  --g: 15px;
  /* control the gap */
  --c: #0b486b;

  width: fit-content;
  padding: 0 1em;
  line-height: 1.6em;
  border: 1px solid;
  color: #fff;
  background:
    conic-gradient(from 45deg at left, var(--c) 25%, #0000 0) 0,
    conic-gradient(from -135deg at right, var(--c) 25%, #0000 0) 100%;
  background-size: 51% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  border-image: linear-gradient(
      #0000 calc(50% - var(--b) / 2),
      var(--c) 0 calc(50% + var(--b) / 2),
      #0000 0
    )
    1/0 var(--w) / calc(var(--w) + var(--g));
  margin-inline: auto;
}

.ai-training-data-status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 360px;
}

.ai-training-data-status svg {
  border-radius: 50%;
}

.ai-training-data-status svg:hover {
  box-shadow: 0 0 8px #1e4a7a;
}

.ai-training-status-text {
  top: 290px;
  font-size: 75%;
  font-weight: 400;
}

.ai-training-new {
  background-color: #aaaaaa;
}

.ai-training-running {
  background-color: #ffb802;
}

.ai-training-done {
  background-color: #03a400;
}

.ai-training-error {
  background-color: #ff0000;
}

.error-msg {
  font-size: 14px;
  color: #ff0000;
}

.ai-model-info {
  padding: 10px 5px;
  margin-top: -265px;
}

.ai-task-name-short {
  margin-left: -35px;
}

.ai-task-name-label {
  position: relative;
}

.ai-task-pin-head,
.ai-task-expense,
.ai-task-prediction-status,
.ai-training-status-text {
  position: absolute;
  z-index: 2;
}

.ai-task-pin-head {
  height: 40px;
  border: 1px solid #33333388;
  width: 1px;
  top: 103px;
}

.ai-task-expense {
  top: 145px;
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  font-size: 75%;
  line-height: 1.6;
}

.ai-task-prediction-status {
  top: 340px;
}

.ai-task-pin-head::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: -3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #33333388;
}

.pt-15 {
  padding-top: 15px;
}

.ai-task-pin-head.ai-work-flow {
  top: 249px;
  z-index: 10;
}

.ai-task-pin-head.ai-work-flow::before {
  top: 35px;
  right: -3px;
}

.navlink {
  background-color: white;
}

.mt-7 {
  margin-top: 7%;
}

.error-br {
  border-color: #ff0000 !important;
}

.border-red {
  border: 1px solid #ff0000;
}

.loader-center {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
  z-index: 9999;
}

.loader-center.masked {
  background-color: white;
}

.avatar-circle {
  background-color: #217ce8;
  border-radius: 50%;
  height: 3.5rem;
  height: var(--avatar-size);
  text-align: center;
  width: 3.5rem;
  width: var(--avatar-size);
}

.avatar-circle:hover {
  background-color: #1e6bc6;
}

.avatar-initials {
  color: #fff;
  font-size: 1.75rem;
  font-size: calc(var(--avatar-size) / 2);
  line-height: 1;
  position: relative;
  top: 0.875rem;
  top: calc(var(--avatar-size) / 4);
}

.ai-roadmap.chart-container {
  margin: 0 auto;
  width: 80%;
  text-align: center;
}

.ml-170 {
  margin-left: 170px;
}

.ai-roadmap-breakdown-arrow {
  margin: 0 auto;
}

.pin-bar-chart {
  width: 100%;
  height: 500px;
  margin-bottom: 30px;
}

.breakdown-chart {
  height: 200px;
}

.row-5 {
  display: flex;
  justify-content: space-between;
}

.ai-breakdown-item {
}

.page-title {
  margin-bottom: 20px;
}

.page-404 {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-40%);
}

.ai-pred-chart-container {
  height: 800px;
}

.ai-data-upload-status {
  color: #555;
  text-align: start;
}

.ai-data-line {
  margin: 5px auto;
}

.ai-data-label {
  margin: 0 15px;
  font-size: 10px;
}

.ai-data-status {
  padding: 1px 6px;
  border-radius: 50%;
  background-color: #a6a4a4;
}

.ai-data-status.completed {
  background-color: #03a400;
}

.ai-data-status.in-progress {
  background-color: #ffb802;
}

.ai-data-status.not-started {
  background-color: #a6a4a4;
}

.ai-data-status.error {
  background-color: #dc3545;
}

.form-group .multi-dropdown {
  padding: 0;
}

.searchBox {
  width: 100%;
}

.optionListContainer {
  z-index: 100 !important;
  background-color: whitesmoke;
}

.multiselect-container {
  border: 0;
  border-bottom: 1px solid #dddddd8f;
  border-radius: 0;
}

.border-bottom {
  border: 0;
  border-bottom: 1px solid #dddddd8f;
  border-radius: 0;
}

.multiselect-container .search-wrapper {
  border: 0;
  background-color: rgba(255, 255, 255, 0.171);
  font-size: 14px;
}

.multiselect-container .search-wrapper::after {
  content: "";
  width: 15px;
  height: 15px;
  /* border: 7px solid transparent;
	 border-color: #007BFF transparent transparent transparent; */
  position: absolute;
  top: 13px;
  right: 10px;
  background-image: url(assets/images/Form-Arrow.svg);
  background-repeat: no-repeat;
  background-position: 55% center;
  transition: all 400ms;
}

.zi-120 {
  z-index: 120;
}

.taskbox1 {
  width: 250px;
  position: absolute;
  top: 100px;
  left: 170px;
}

.taskbox-shadow {
  box-shadow: 5px 8px 15px #ddd;
}

.taskbox2 {
  width: 250px;
  position: absolute;
  top: 210px;
  left: 94px;
}

.taskbox3 {
  width: 200px;
  position: absolute;
  left: 40%;
  top: 200px;
}

.taskmainsec {
  margin-top: 200px;
}

.multiSelectContainer ul {
  max-height: 300px !important;
}

.multiselect-container .searchBox {
  /* color: #fff !important; */
}

.multiselect-container .searchBox::placeholder {
  /* color: #fff !important; */
}

.df-file-selected {
  word-break: break-all;
  color: #007bff;
}

.multiSelectContainer li {
  color: #333;
  padding: 5px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.multiselect-container .chip {
  white-space: inherit;
}

.singleChip {
  background-color: #0096fb;
  color: #fff;
}

.ai-recent-models {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.ai-rm-model-name {
  text-transform: capitalize;
  font-weight: bold;
}

.ai-rm-model img {
  width: 100%;
  height: 200px;
}

.singleSelect .icon_down_dir {
  display: none;
}

.task-add-more {
  color: white;
}

.task-section {
  /* padding: 10px; */
}

.dashed-border {
  border-bottom: 1px dashed gray;
}

.task-add-more:hover {
  color: white;
}

.task-remove {
  color: gray;
}

.task-expense {
  top: 50px;
}

.task-remove:hover {
  color: #dc3545 !important;
}

.message-white {
  color: white;
}

.ai-task-divider {
}

.ai-model-name-input {
  display: flex;
  justify-content: flex-start;
}

.ai-action-icon {
  width: 40px;
  height: 30px;
}

.ai-sm-icon {
  width: 20px;
  height: 20px;
}

.ai-divider-img {
  position: absolute;
  width: 21px;
  top: 143px;
  z-index: 20;
  left: 41px;
}

.ai-dynamic-model-color {
  height: 150px;
  position: absolute;
  top: 249px;
}

.ai-task-model-name {
  position: relative;
  transform: rotate(90deg);
  top: 50px;
}

.custom-dropdown-label {
  /* padding: 5px 15px; */
}

.capitalize {
  text-transform: capitalize;
}

select,
select option {
  text-transform: capitalize;
}

.full-width-modal.modal-dialog {
  width: 96%;
  max-width: 100%;
}

.full-width-modal .modal-content {
  border-radius: 0;
  width: 100%;
  margin: 0;
}

.full-width-modal-mobile.modal-dialog {
  width: 100%;
  margin: 0;
}

.full-width-modal-mobile .modal-content {
  border-radius: 0;
  width: 100%;
  margin: 0;
}

.ai-rm-template-item img {
  width: 300px;
  height: 150px;
}

.imgdetctble.table td,
.imgdetctble.table th {
  vertical-align: middle;
}

/*************** NEW UI STYLES ***************/
.timeline {
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline li div:first-child {
  margin-bottom: 10px;
}

.timeline li {
  width: 33%;
  opacity: 0.5;
  position: relative;
}

.timeline .nmbr {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #007bff;
  position: relative;
  color: #fff;
  font-size: 15px;
  margin: auto;
}

.timeline li:after {
  content: "";
  position: absolute;
  width: 67%;
  height: 2px;
  background-color: grey;
  top: 74%;
  left: -33%;
  z-index: -999;
  transform: translateY(-50%);
  transition: all ease-in-out 0.3s;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.timeline li:first-child:after {
  content: none;
}

.timeline li.active {
  opacity: 1;
}

.rightbar-outer {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  /* z-index: 2000; */
  height: 100%;
  box-shadow: 0px 0px 10px #ddd;
  overflow: auto;
  padding-bottom: 50px;
}

.rightbar {
  padding: 10px;
  width: 100%;
  max-height: calc(100% - 60px);
  overflow: auto;
}

.rightbar::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar track */
  height: 6px;
  border-radius: 6px;
}

.rightbar::-webkit-scrollbar-thumb {
  background-color: black;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

.rightbar select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(assets/images/Form-Arrow.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
  font-family: "Courier New", Courier, monospace;
}

.rightbar select.form-control {
  border: 0;
  border-bottom: 1px solid #ddd;
}

.rightbar select.form-control option {
  background-color: #fff;
  border: 0;
  padding: 10px !important;
  border-bottom: 1px solid #ddd !important;
}

.rightbar .form-control {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
}

.rightbar .form-control::placeholder {
  opacity: 0.5;
}

.rightbar .form-control:focus {
  background-color: transparent !important;
}

.radious-0 {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.bg-light-grey {
  background-color: #c0c7d0;
}

.bg-transparent {
  background-color: transparent;
}

.font15fontweight600 {
  font-size: 14px;
  font-weight: 600;
}

.btn3 {
  border: 2px solid #007bff;
}

.btn3 {
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}

.btn3:hover {
  background-color: #007bff;
  color: #fff;
}

.dividerline {
  width: 70px;
  height: 2px;
  background-color: #ccc;
  margin: auto;
}

.successalertbar {
  background-color: #e3fde0;
  padding: 10px 15px;
  width: 450px;
  position: absolute;
  box-shadow: 2px 5px 10px #ddddddb0;
  top: -100px;
  top: -86px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  border: 1px solid #dddddd8f;
}

.text-grey {
  color: #464646;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: rgb(73, 73, 73);
  width: 100%;
  height: 30px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 2px 27px 2px 0px;
  border-bottom: 1px solid #dddddd8f;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-styled:after {
  content: "";
  width: 15px;
  height: 15px;
  /* border: 7px solid transparent;
	 border-color: #007BFF transparent transparent transparent; */
  position: absolute;
  top: 5px;
  right: 5px;
  background-image: url(assets/images/Form-Arrow.svg);
  background-repeat: no-repeat;
  background-position: 55% center;
  transition: all 400ms;
}

/* .select-styled:hover {
	 background-color: #b83729;
} */
.select-styled:active,
.select-styled.active {
  background-color: #fff;
}

.select-styled:active:after,
.select-styled.active:after {
  top: 13px;
  right: 10px;
  background-image: url(assets/images/Form-Arrow.svg);
  background-repeat: no-repeat;
  background-position: 55% center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: all 400ms;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  box-shadow: 0px 0px 15px #ddd;
  max-height: 250px;
  overflow: auto;
}

.select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-options li:last-child {
  border-bottom: 0px solid #962d22;
}

.select-options li:hover {
  color: #007bff;
  background: #fff;
}

.select-options li[rel="hide"] {
  display: none;
}

.copyright {
  color: #ccc;
}

footer.copyright {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  bottom: 0;
  font-size: 14px;
  padding: 7px 0;
  border-top: 1px solid #ddd;
}

.copyright .contnt {
  width: 60%;
  margin: auto;
}

footer.copyright a {
  color: #ccc;
  transition: all 400ms;
}

.p-l-190 {
  padding-left: 190px;
}

.p-l-35 {
  padding-left: 35px;
}

footer.copyright a:hover {
  color: #999999;
}

/********************************** MODELS COLOR SHADES start*********************************************/

.shadebox {
  height: 200px;
  display: inline-block;
}

.Orangebox {
  background-color: #fce6d8;
  font-size: 13px;
}

.Orangebox h6 {
  color: #eb702c;
}

.Orangebox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.Orangeshade {
  background: linear-gradient(
    180deg,
    rgba(252, 230, 216, 1),
    rgba(252, 230, 216, 0)
  );
}

.violetebox {
  background-color: #d8e0fc;
  font-size: 13px;
}

.violetebox h6 {
  color: #7f97ec;
}

.violetebox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.violeteshade {
  background: linear-gradient(
    180deg,
    rgba(216, 224, 252, 1),
    rgba(216, 224, 252, 0)
  );
}

.yellowbox {
  background-color: #f3f2d3;
  font-size: 13px;
}

.yellowbox h6 {
  color: #c7bf0b;
}

.yellowbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.yellowshade {
  background: linear-gradient(
    180deg,
    rgba(243, 242, 211, 1),
    rgba(243, 242, 211, 0)
  );
}

.cyanbox {
  background-color: #d9f3fb;
  font-size: 13px;
}

.cyanbox h6 {
  color: #51c3e8;
}

.cyanbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.cyanshade {
  background: linear-gradient(
    180deg,
    rgba(217, 243, 251, 1),
    rgba(217, 243, 251, 0)
  );
}

.purplebox {
  background-color: #ede0f6;
  font-size: 13px;
}

.purplebox h6 {
  color: #a67ac8;
}

.purplebox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.cyanshade {
  background: linear-gradient(
    180deg,
    rgba(237, 224, 246, 1),
    rgba(237, 224, 246, 0)
  );
}

.goldenbox {
  background-color: #f8f1e0;
  font-size: 13px;
}

.goldenbox h6 {
  color: #e7b025;
}

.goldenbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.goldenshade {
  background: linear-gradient(
    180deg,
    rgba(248, 241, 224, 1),
    rgba(248, 241, 224, 0)
  );
}

.lgreenbox {
  background-color: #e7f6d4;
  font-size: 13px;
}

.lgreenbox h6 {
  color: #80bf2e;
}

.ai-model-box {
  /* margin: 15px auto; */
}

.donut-legend-item {
  width: 30px;
  height: 20px;
  border: 1px solid white;
}

.donut-legend .label {
}

.donut-legend .value {
  font-size: 18px;
}

.scrolwrap {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 20px;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar track */
  height: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track */
}

/* Customize the scrollbar */

.lgreenbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.lgreenshade {
  background: linear-gradient(
    180deg,
    rgba(231, 246, 212, 1),
    rgba(231, 246, 212, 0)
  );
}

.redbox {
  background-color: #fae5e5;
  font-size: 13px;
}

.redbox h6 {
  color: #c17272;
}

.redbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.redshade {
  background: linear-gradient(
    180deg,
    rgba(250, 229, 229, 1),
    rgba(250, 229, 229, 0)
  );
}

.pinkbox {
  background-color: #fce4f9;
  font-size: 13px;
}

.pinkbox h6 {
  color: #c047b4;
}

.pinkbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.pinkshade {
  background: linear-gradient(
    180deg,
    rgba(252, 228, 249, 1),
    rgba(252, 228, 249, 0)
  );
}

.dgreenbox {
  background-color: #d7ebd5;
  font-size: 13px;
}

.dgreenbox h6 {
  color: #5c9e56;
}

.dgreenbox ul li {
  margin-bottom: 20px;
  list-style: disc;
  margin-left: 15px;
}

.dgreenshade {
  background: linear-gradient(
    180deg,
    rgba(215, 235, 213, 1),
    rgba(215, 235, 213, 0)
  );
}

/********************************** MODELS COLOR SHADES end *********************************************/

/********************************** Unsigned pages start *********************************************/

.Bannersec {
  position: relative;
}

.calulatorbox {
  background-color: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.164);
}

.hide {
  display: none;
}

.hightlightbox {
  background-image: url(assets/images/right-bg.jpg);
  background-size: cover;
  width: 100%;
  min-height: 500px;
}

.hightlightboxinner {
  width: 350px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #007bff;
  height: 250px;
  background-color: #fff;
  font-weight: 600;
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.164);
}

.extrapadding {
  padding-left: 80px;
  padding-right: 80px;
}

.hightlightboxinner span {
  color: #007bff;
}

.range-sliders input[type="range"] {
  width: 100%;
  background: #007bff61;
  border-radius: 8px;
  height: 6px;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

.numbrings,
.input-slider,
.range-slider-group {
  position: relative;
}

.range-slider-group::before {
  content: attr(data-min);
  position: absolute;
  top: 90px;
  left: 0;
  height: 100%;
  color: #000;
  z-index: 99;
}

.range-slider-group::after {
  content: attr(data-max);
  position: absolute;
  top: 90px;
  right: 0;
  height: 100%;
  color: #000;
  z-index: 99;
}

.range-sliders input[type="range"]:focus {
  outline: none;
}

.range-sliders input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background-color: #e6e6e6;
  border-radius: 0;
}

.range-sliders input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background-color: #ccc;
  border-radius: 0;
}

.range-sliders input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  height: 30px;
  width: 12px;
  border-color: transparent;
  border-radius: 22px;
  background-color: #999;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -15px;
}

.range-sliders input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  height: 20px;
  width: 40px;
  border-color: transparent;
  border-radius: 22px;
  background-color: #999;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -15px;
}

.stepbox.flowsec h4 {
  font-size: 11px;
  min-height: 30px;
}

.stepbox.flowsec h3 {
  font-weight: bold;
  font-size: 17px;
}

.tblue.bluestrip span {
  font-size: 30px;
}

.tblue.bluestrip {
  width: 66%;
  margin-left: 16.5% !important;
}

/* .range-sliders input[type=range]::-moz-focus-outer {
 border: 0;
} */

.range-sliders
  input[type="range"].range-slider-red::-webkit-slider-runnable-track {
  background-color: rgba(255, 0, 0, 0.1);
}

.range-sliders input[type="range"].range-slider-red::-moz-range-track {
  background-color: rgba(255, 0, 0, 0.1);
}

.range-sliders input[type="range"].range-slider-red::-webkit-slider-thumb {
  background-color: #007bff;
}

.range-sliders input[type="range"].range-slider-red::-moz-range-thumb {
  background-color: #007bff;
}

.text-blue {
  color: #007bff;
  font-weight: 500;
}

.bg-blue {
  background-color: #217ce8;
}

.badge-indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  top: -4px;
  right: 12px;
  border-radius: 50%;
}

.bannertext {
  width: 400px;
  margin: auto;
  position: relative;
  margin-top: -70px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.bannertext-mob {
  width: 100%;
  margin: auto;
  border-radius: 0;
}

.bannertext-mob .h1 {
  font-size: 20px;
}

.btn1next {
  padding: 12px 25px;
  border: none;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  background: #025bda;
  display: inline-block;
  font-size: 16px;
}

.btn1next2 {
  padding: 12px 35px;
  border: none;
  line-height: 1.4;
  text-align: center;
  color: #025bda;
  background: #fff;
  display: inline-block;
  font-size: 16px;
  border: 1px solid #025bda;
}

.btn1next2:hover {
  color: #fff;
  background: #025bda;
}

.resultbox {
  border: #c7dffa solid 2px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 750px;
}

.resultbox div {
  width: 250px;
}

.resultbox div:nth-child(2) {
  border-left: #c7dffa solid 2px;
  border-right: #c7dffa solid 2px;
}

.resultbox .h2,
.resultbox-mob .h2 {
  font-size: 24px;
}

.resultbox h4,
.resultbox-mob h4 {
  color: #217ce8;
  font-weight: 600;
}

.resultbox-mob {
  flex-direction: column;
  border: #c7dffa solid 2px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-size: 20px;
}

.resultbox-mob div {
  width: 100%;
}

.resultbox-mob div:nth-child(2) {
  border-top: #c7dffa solid 2px;
  border-bottom: #c7dffa solid 2px;
}

.lineheight20 {
  line-height: 1.8;
}

.lineheight10 {
  line-height: 1.4;
}

.resultweeks {
  /* background-color: #f9f7f3; */
  /* border-top: solid 1px #000; */
}

.bold {
  font-weight: 600;
}

.automatebtn .btn1next {
  width: 200px;
  text-transform: uppercase;
}

.timelinebox1 {
  width: 300px;
  position: absolute;
  top: -40px;
  left: -40px;
}

.timelinebox1 div:first-child {
  left: 112px;
  position: relative;
}

.timelinebox1 p {
  width: 161px;
  font-size: 14px;
}

.timelinebox1 div:last-child {
  left: 70px;
  position: relative;
}

.timelinebox2 {
  width: 220px;
  position: absolute;
  top: 162px;
  left: 170px;
}

.timelinebox2 div:first-child {
  left: 67px;
  position: relative;
}

.timelinebox2 p {
  font-size: 14px;
}

.timelinebox2 div:last-child {
  left: 70px;
  position: relative;
}

.timelinebox2 div:last-child {
  left: 157px;
  position: relative;
  top: 16px;
}

.timelinebox3 {
  width: 300px;
  position: absolute;
  top: -40px;
  left: 470px;
}

.timelinebox3 div:first-child {
  left: 175px;
  position: relative;
}

.timelinebox3 p {
  width: 250px;
  font-size: 14px;
}

.timelinebox4 {
  width: 220px;
  position: absolute;
  top: 162px;
  right: 120px;
}

.timelinebox4 div:first-child {
  right: 67px;
  position: relative;
}

.timelinebox4 div:first-child {
  left: 35px;
  position: relative;
}

.timelinebox4 p {
  font-size: 14px;
}

.timelinebox4 div:last-child {
  left: 197px;
  position: relative;
  top: 16px;
}

.timelinebox p {
  height: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resultweeks {
  padding: 70px 0 100px 0;
}

.resullist {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.task-form-label {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.resullist li {
  position: relative;
  width: 50%;
  line-height: 2.2;
  padding-left: 30px;
  text-align: left;
  font-size: 20px;
}

.resullist li::before {
  content: "";
  position: absolute;
  background-image: var(--img);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.ai-report-section {
  position: relative;
}

.ai-report-box {
}

.mask-color {
  background-color: #ffffff88;
}

.ai-report-mask {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffffe0;
}

.hightlightboxinner-mask {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

ul.ai-solutions {
}

.ai-solution-item {
}

.ai-solution-item-icon {
  width: 50px;
}

.ai-solution-item-name {
}

.revenue-box {
  background-color: #2196f31a;
}

.revenue-box .ai-value-title {
  color: #2196f3;
}

.material-box {
  background: #9bd81926;
}

.material-box .ai-value-title {
  color: #9bd819;
}

.manpower-box {
  background: #f379211a;
}

.manpower-box .ai-value-title {
  color: #f37921;
}

.recent-item-option {
  position: absolute;
  transform: translate3d(-109px, 40px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.btnblue {
  border-color: #007bff;
  color: #007bff;
}

.btnblue:hover {
  background-color: #cee5ff;
}

.btnorange {
  color: #ef873c;
  background-color: #fff1e6;
  border: #ef873c 1px solid;
}

.btnorange:hover {
  background-color: #ef873c2f !important;
}

.btngreen {
  color: #077a20;
  background-color: #e0ffe7;
  border: #077a20 1px solid;
}

.btngreen:hover {
  background-color: #077a203d !important;
}

.machine-box {
  background: #9459c133;
}

.machine-box .ai-value-title {
  color: #9459c1;
}

.ai-value-model-list {
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.ai-value-model-list .ai-value-title {
  font-weight: 700;
  font-size: 18px;
}

ul.ai-value-listitem {
  margin: 10px 0;
}

.image-container {
  position: relative;
}

.image-trash-icon {
  position: absolute;
  top: -15px;
  /* Adjust the top and right values as needed to position the icon */
  right: 0;
  opacity: 0;
  /* Initially hide the trash icon */
  transition: opacity 0.3s ease;
  /* Add a transition effect for a smooth appearance */
}

.image-container:hover .image-trash-icon {
  opacity: 1;
  /* Show the trash icon on hover */
}

.ai-multi-layout-right-container {
  flex-direction: column;
  display: flex;
  max-height: 70vh;
  overflow: auto;
  align-items: center;
}

.ai-ic-modal-content {
  flex-direction: column;
  display: flex;
  min-height: 30vh;
  max-height: 50vh;
  overflow: auto;
  align-items: center;
}

.ai-image-preview {
  /* height: 200px; */
}

.bl-separator {
  border-left: 1px solid lightgray;
}

.ai-image-selected {
  border: red 2px solid;
}

ul.ai-value-listitem li {
  font-size: small;
  margin: 5px auto;
}

.form-group .form-control {
  height: auto;
}

.rounded-50 {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.quirymdl .close {
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  right: 12px;
  position: relative;
  top: 6px;
}

.subheading1 {
  border-bottom: solid 2px #217ce8;
}

.subheading2 {
  border-bottom: solid 2px #ffb802;
}

.subheading3 {
  border-bottom: solid 2px #058447;
}

.left-42::before {
  left: 42%;
}

.blue-thin-bar {
  color: #00c2ff !important;
}

.left-42::after {
  right: 42%;
}

.recentmodelbox {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  box-shadow: 0px 0px 10px #ddd;
}

.recent-model-img {
  height: 80px;
}

.serchrghtselect .custom-dropdown .dropdown-menu {
  position: absolute;
  left: 0px;
  top: auto;
  bottom: 50px;
}

.chtbot-query-details {
  min-height: 70vh;
  overflow: auto;
}

.modeling img {
  height: 90px;
  object-fit: cover;
}

.bg-none {
  background: none;
}

.regressiontable {
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-height: 400px;
  overflow: auto;
}

.regressionactions {
  gap: 16px;
}

.regressioncellinput {
  width: 100%;
  box-sizing: border-box;
}

.regressiontable .regressionrow:nth-child(odd) {
  background-color: #efefef;
}

.regressionrow {
  display: flex;
}

.regressionheader {
  font-weight: bold;
}

.regressioncell {
  flex: 1;
  border: 1px solid #ccc;
  padding: 5px;
}

.regressionheader .regressioncell {
  background-color: #e1efff;
  word-break: break-all;
}

.upload-item-text-wrap {
  text-overflow: ellipsis;
  white-space: pre;
  width: 160px;
  overflow: hidden;
}

.regressioncellhead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.celllabel {
}

.min-vh-50 {
  min-height: 50vh;
}

.react-toast-notifications__container {
  z-index: 10000 !important;
  top: 75px !important;
}

.usr-timeline-note-text {
  position: absolute;
  bottom: -95px;
}

.flowprocessbox-m {
  display: none;
}

.ai-image-grid {
  gap: 10px;
}

.ai-image {
  min-height: 150px;
  max-height: 150px;
}

.dotwavebox .dotwave-circle:nth-child(1) {
  top: 70px;
  left: 32%;
}

.dotwavebox .dotwave-circle:nth-child(2) {
  top: 240px;
  left: 15%;
}

.dotwavebox .dotwave-circle:nth-child(3) {
  top: 180px;
  left: 50%;
}

.dotwave-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  cursor: pointer;
  z-index: 100;
}

.dotwave-circle::before {
  content: "";
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -46%;
  margin-top: -68%;
  border-radius: 45px;
  background-color: #007bff;
  -webkit-animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  opacity: 0.55;
}

.dotwave-circle:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 0;
  display: block;
  width: 17px;
  height: 17px;
  background-color: #007bff;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.chtbothstrybox {
  background-color: #1d1d1d;
  height: 100%;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.1);
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.1);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

/********************************** Unsigned pages end *********************************************/

/********************************** Ask me anythigng start ***********************************************/

.askmeanything2::placeholder {
  opacity: 1;
}

.question-list {
  display: flex;
  flex-wrap: wrap;
}

.question-list li {
  width: 50%;
  line-height: 1.8;
  color: #ccc;
  margin: 5px 0;
}

.question-list li button {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: white;
}

.chtbothstrybox {
  background-color: #1d1d1d;
  height: 100%;
}

.SelectedTasksbox {
  background-color: #1d1d1d;
  height: 100%;
  transform: translateX(100%);
  transition: all 400ms;
  position: absolute;
  z-index: 9999999;
  top: 0;
  right: 0;
}

.SelectedTasksbox.showselectedtask {
  transform: translateX(0);
}

.askmeanything3 {
  font-size: 18px;
  font-weight: 500;
}

.chkpdctionhover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000073;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 400ms;
  opacity: 0;
  transform: translateY(15px);
}

.chkpdctionsec {
  overflow: hidden;
}

.chkpdctionsec:hover .chkpdctionhover {
  opacity: 1;
  transform: translateY(0px);
}

/* .checktasks {
    padding: 10px;
} */
.chkChatGpt {
  width: 20px;
  height: 20px;
}

.checktasks label input[type="checkbox"] {
  width: 100%;
  opacity: 0;
}

.checktasks label span.tskchk {
  border: 2px solid #007bff00;
  display: block;
  padding: 0px;
  position: relative;
  cursor: pointer;
  transition: all 300ms;
}

.checktasks label span.tskchk::before {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #007bff;
  left: -1px;
  top: -1px;
  position: absolute;
  /* opacity: 0; */
  transition: all 300ms;
}

.checktasks label span.tskchk::after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 4px;
  width: 5px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 300ms;
}

.checktasks label input[type="checkbox"] ~ span.tskchk {
  border: 1px solid #007bff;
  padding: 20px 20px;
}

.checktasks label input[type="checkbox"]:checked ~ span.tskchk::after,
.checktasks label input[type="checkbox"]:checked ~ span.tskchk::before {
  opacity: 1;
}

.chatGpt label input[type="checkbox"] ~ span.tskchk {
  padding: 10px;
  border: none;
}

.chatGpt label input[type="checkbox"] ~ span.tskchk::before,
.chatGpt label input[type="checkbox"] ~ span.tskchk::after {
  top: 20px;
  margin-left: -20px;
}

.selctedtsklist li {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.chtbotmidsec {
  height: calc(100vh - 150px);
  overflow: auto;
}

.chtbotmidsec::-webkit-scrollbar-thumb {
  background: #025bda;
}

.chtbotmidsec::-webkit-scrollbar {
  width: 1px;
}

/************* *************** */
.checkbox-wrapper-switch input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-switch *,
.checkbox-wrapper-switch ::after,
.checkbox-wrapper-switch ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-switch .rocker {
  display: inline-block;
  position: relative;
  /*
    SIZE OF SWITCH
    ==============
    All sizes are in em - therefore
    changing the font-size here
    will change the size of the switch.
    See .rocker-small below as example.
    */
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #888;
  width: 7em;
  height: 4em;
  overflow: hidden;
  border-bottom: 0.5em solid #eee;
}

.checkbox-wrapper-switch .rocker-small {
  font-size: 0.75em;
}

.checkbox-wrapper-switch .rocker::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;
  border: 0.5em solid #eee;
  border-bottom: 0;
}

.checkbox-wrapper-switch .switch-left,
.checkbox-wrapper-switch .switch-right {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 3em;
  transition: 0.2s;
  user-select: none;
}

.checkbox-wrapper-switch .switch-left {
  height: 2.4em;
  width: 2.75em;
  left: 0.85em;
  bottom: 0.4em;
  background-color: #ddd;
  transform: rotate(15deg) skewX(15deg);
}

.checkbox-wrapper-switch .switch-right {
  right: 0.5em;
  bottom: 0;
  background-color: #bd5757;
  color: #fff;
}

.checkbox-wrapper-switch .switch-left::before,
.checkbox-wrapper-switch .switch-right::before {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 2.45em;
  bottom: -0.45em;
  background-color: #ccc;
  transform: skewY(-65deg);
}

.checkbox-wrapper-switch .switch-left::before {
  left: -0.4em;
}

.checkbox-wrapper-switch .switch-right::before {
  right: -0.375em;
  background-color: transparent;
  transform: skewY(65deg);
}

.checkbox-wrapper-switch input:checked + .switch-left {
  background-color: #0084d0;
  color: #fff;
  bottom: 0px;
  left: 0.5em;
  height: 2.5em;
  width: 3em;
  transform: rotate(0deg) skewX(0deg);
}

.checkbox-wrapper-switch input:checked + .switch-left::before {
  background-color: transparent;
  width: 3.0833em;
}

.checkbox-wrapper-switch input:checked + .switch-left + .switch-right {
  background-color: #ddd;
  color: #888;
  bottom: 0.4em;
  right: 0.8em;
  height: 2.4em;
  width: 2.75em;
  transform: rotate(-15deg) skewX(-15deg);
}

.checkbox-wrapper-switch input:checked + .switch-left + .switch-right::before {
  background-color: #ccc;
}

/* Keyboard Users */
.checkbox-wrapper-switch input:focus + .switch-left {
  color: #333;
}

.checkbox-wrapper-switch input:checked:focus + .switch-left {
  color: #fff;
}

.checkbox-wrapper-switch input:focus + .switch-left + .switch-right {
  color: #fff;
}

.checkbox-wrapper-switch input:checked:focus + .switch-left + .switch-right {
  color: #333;
}

/****** Normal Switch **********/
.checkbox-wrapper-64 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-64 *,
.checkbox-wrapper-64 ::after,
.checkbox-wrapper-64 ::before {
  box-sizing: border-box;
}

/* The switch - the box around the slider */
.checkbox-wrapper-64 .switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.checkbox-wrapper-64 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.checkbox-wrapper-64 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.checkbox-wrapper-64 .slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

.checkbox-wrapper-64 input:checked + .slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

.checkbox-wrapper-64 input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

.checkbox-wrapper-64 input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

/********* Normal switch ***********/
/*********** ************ */
.askme-workflow-preview {
  background-color: white;
  height: 150px;
}

ul.ul-bullet {
  list-style: disc;
}

.tooltip {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.tooltip-arrow {
}

.tooltip-inner {
  background-color: #fff;
  text-align: left;
  color: #333;
  min-width: 400px;
}

.chart-custom-tooltip {
  background-color: #fff;
  padding: 5px;
  border: 0;
  outline: none;
}

.modal-header {
  align-items: center;
}

/* Funel ccss */
.funelcontnt {
  background-image: url(assets/images/funel/funel-blank.svg);
  height: 450px;
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  padding: 10px 5px;
  width: 90px;
}

.funelcontnt.first-item:first-child:before {
  content: "";
  position: absolute;
  top: 38%;
  left: -31px;
  width: 66px;
  height: 136px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(assets/images/funel/funel-cicle.svg);
}

.funelcontnt::after {
  content: "";
  position: absolute;
  top: 23px;
  right: -25px;
  width: 25px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(assets/images/funel/funel-dotted-line.svg);
  z-index: 1;
}

.funelcontnt.last-item:last-child::after {
  content: "";
  position: absolute;
  top: 0px;
  right: -29px;
  width: 34px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(assets/images/funel/funel-end.svg) !important;
  z-index: 1;
}

.fuelhdng {
  top: 33px;
  z-index: 1;
  left: 10px;
}

.font-12 {
  font-size: 12px;
  line-height: 1.3;
}

.fuelhdng .font-12 {
  font-size: 14px;
}

.absolute-full-width {
  left: 0;
  right: 0;
}

.funlsecncontent {
  min-width: 80px;
  top: 95px;
  padding: 0px 10px;
  text-align: center;
  position: absolute;
}

.funlmidcontent {
  margin-top: 215px;
  padding-left: 34px;
  position: absolute;
}

.funlsecnlstcontent {
  gap: 15px;
  top: 315px;
  padding-left: 20px;
  position: absolute;
}

.funlsecnuploadcontent {
  gap: 15px;
  top: 350px;
  padding-left: 20px;
  position: absolute;
}

.funllstcontent {
  min-width: 80px;
  /* margin-top: 25px; */
  padding-left: 5px;
  text-align: center;
  position: absolute;
  bottom: 30px;
}

.funelvideo {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  left: 50%;
}

.funelcontnt2 {
  background-image: url(assets/images/funel/funel-blue.svg);
  height: 450px;
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  padding: 10px 5px;
  width: 90px;
}

.marketingfnlhdng {
  justify-content: center;
}

.funelcontnt3 {
  background-image: url(assets/images/funel/funel-violet.svg);
  height: 450px;
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  padding: 10px 5px;
  width: 90px;
}

.txt-elipises {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ai-recommendation-table,
th,
td {
  border: 1px solid #ccc;
}

.ai-recommendation-table thead tr {
  background-color: #eee;
  color: #666;
}

.ai-recommend-list-view {
  list-style-type: disc;
}

.pie-legend {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}

.ai-data-crm-icon {
  width: 100px;
}

.pie-legend-box {
  width: 25px;
  height: 25px;
}

.swot-container {
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.evaluate-summary-container {
  border: 1px solid #ddd;
}

.evaluate-summary-content {
  flex: 1;
  align-items: center;
}

.evaluate-summary-content p span {
  font-weight: bold;
  color: #888;
}

.evaluate-summary-content:nth-child(2),
.evaluate-summary-content:nth-child(4) {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-16 {
  flex-basis: 16.66%;
}

.swot-container.flex-basis-50:nth-child(2n) {
  border-left: none;
}

.swot-header {
  border: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

.swot-content {
  padding: 10px;
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon.eot?xixjef");
  src:
    url("assets/fonts/icomoon.eot?xixjef#iefix") format("embedded-opentype"),
    url("assets/fonts/icomoon.ttf?xixjef") format("truetype"),
    url("assets/fonts/icomoon.woff?xixjef") format("woff"),
    url("assets/fonts/icomoon.svg?xixjef#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font Styles */
.icon-ai-data:before {
  content: "\e900";
}

.icon-ai-insight:before {
  content: "\e901";
}

.icon-ai-model:before {
  content: "\e902";
}

.icon-ai-output:before {
  content: "\e903";
}

.icon-ai-prediction:before {
  content: "\e904";
}

.icon-ai-roadmap:before {
  content: "\e905";
}

.icon-ai-strategy:before {
  content: "\e906";
}

.icon-ai-training:before {
  content: "\e907";
}

.icon-ai-transformation:before {
  content: "\e908";
}

.icon-ai-value:before {
  content: "\e909";
}

.icon-ai-workflow:before {
  content: "\e90a";
}

.icon-home:before {
  content: "\e90b";
}

.icon-model-connect:before {
  content: "\e90c";
}

/********************************** Ask me anythigng End   ***********************************************/

/****************************************   Custome styles Vishwas End ********************************************* */
/* Media Query*/
@media screen and (max-width: 992px) {
  .logo img {
    width: 120px;
  }

  big {
    font-size: 120%;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 22px;
  }

  h1 {
    font-size: 40px;
  }

  .loginFrm {
    height: 100%;
  }

  .leftbox {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .sitetitle {
    font-size: 12px;
    font-weight: 500;
  }

  .loginFrm {
    width: 100%;
    margin: 00px auto 0;
    height: 100%;
    padding: 0 15px;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .sitetitle span img {
    width: 40px;
    height: 40px;
  }

  .sitetitle span {
    width: 40px;
    height: 40px;
  }

  .frmbtnsets {
    flex-direction: column;
  }

  .frmbtnsets .btn1 {
    width: 100%;
    font-size: 15px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  /* Your CSS styles for mobile devices go here */
  .mp-5 {
    padding: 0.5rem !important;
  }

  .mp-4 {
    padding: 0.5rem !important;
  }

  .mm-5 {
    margin: 0.5rem !important;
  }

  .dashboardwrap.active {
    display: none;
  }

  .flowprocessbox {
    display: none;
  }

  .flowprocessbox-m {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.5rem;
  }

  .flowprocessbox-item {
  }

  .flowprocessbox-item::before {
    content: "";
    padding: 0px 10px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .flowprocessbox-item.process-not-started::before {
    background-color: #a6a4a4;
  }

  .flowprocessbox-item.process-in-progress::before {
    background-color: #ffb802;
  }

  .flowprocessbox-item.process-done::before {
    background-color: #03a400;
  }

  .flowprocessbox-item.process-error::before {
    background-color: #ff0000;
  }

  .full-width-modal .modal-content {
    max-height: 90vh;
    overflow: auto;
  }

  .full-width-modal-mobile .modal-content {
    min-height: 100vh;
    overflow: auto;
  }

  .popuptabs .tab-content {
    min-height: 100vh;
    max-height: fit-content;
  }

  .tab-content > .active,
  .evaluate-ai .tab-content > .active {
    height: 100%;
  }

  .table-container {
    height: 100%;
  }

  .regressiontable {
    max-height: none;
    padding-bottom: 15px;
  }

  .regressionrow {
    display: grid;
    border-bottom: 2px solid #007bff;
  }

  .revenuetbl thead {
    background: red;
  }

  .modal-dialog .modal-body {
    padding: 15px;
  }

  .regressionheader {
    display: none;
  }

  .regressioncell .mobile-content {
    display: none;
  }

  .regressioncell::before {
    content: attr(datacol);
  }

  .regressioncell::after {
    content: attr(dataval);
    float: right;
  }

  .regressioncell:last-child {
    background-color: #007bff22;
    font-weight: bold;
  }

  .modal-header {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }

  .nav.nav-tabs {
    justify-content: center;
  }

  .nav.nav.nav-tabs .nav-item {
    flex: 1 0 0;
  }

  .bo .nav.nav-tabs {
    flex-direction: column;
    border-bottom: 1px solid #ddd;
  }

  #myTab .nav-item .nav-link {
    padding: 5px;
  }

  .imgdetctble.table thead {
    display: none;
  }

  .imgdetctble.table tr {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid;
  }

  .imgdetctble.table td p .mobile-content {
    display: none;
  }

  .btn-document-ai-download {
    width: 100%;
  }

  .imgdetctble.table td p::before {
    content: attr(datacol);
    color: gray;
    float: left;
    font-weight: bold;
    text-transform: capitalize;
  }

  .imgdetctble.table td p::after {
    content: attr(dataval);
    float: right;
  }

  .btnEdit {
    float: right;
  }

  .imgdetctble.table td.w-15 {
    width: 100%;
  }
}
